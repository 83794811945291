<template>
<div>
  <b-card>
    <label for="wildcard">Importeer .jpg foto's</label>
    <b-form-file
      class="mb-2"
      accept=".jpg, .JPEG"
      v-model="files"
      multiple
      @input="handleDrop"
    />

    <b-button
      variant="primary"
      @click="importPhotos"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :disabled="loading"
    >
      
      Foto's importeren
    </b-button>

  </b-card>
</div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BSpinner,
  BAlert,
  BFormFile,
  BTable,
  BMedia,
  BMediaAside,
  BMediaBody,
  BOverlay,
} from "bootstrap-vue";

import vSelect from "vue-select";

import Ripple from "vue-ripple-directive";

import XLSX from "xlsx";

import { db } from "@/firebase/firebaseConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import mainMixin from '@/mixins/mainMixin';

export default {
  mixins: [mainMixin],
  directives: { Ripple },
  components: {
    BFormCheckbox,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BSpinner,
    BAlert,
    BFormFile,
    BTable,
    BMedia,
    BMediaAside,
    BMediaBody,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      files: [],
      loading: false,
      
    };
  },
  created() {
    this.fetchUsers();
  },
  
  methods: {
    async importPhotos(){
      this.loading = true;
      var promises = [];
      this.files.forEach(f => {
        var u = this.$store.state.userManagement.users.filter((u) => u.uoid == f.name.split('.')[0])[0];
        if(u){
          promises.push(this.uploadFile(u, f));
        }
        
      });
      await Promise.all(promises);
      this.$toast({
              component: ToastificationContent,
              props: {
                title: "Profielfotos geimporteerd!",
                icon: "CheckICon",
                variant: "success",
              },
            });
      this.loading = false;
    },
    
    
    handleDrop(e) {
      // only use files[0]
    },

   

    

    uploadFile(user, file){
      return this.$store.dispatch('uploadImageToPath', {
        imageName: `/organisations/${this.$store.state.organisation.id}/users/${user.uid || user.uoid}/${user.uid || user.uoid}.${file.name.split('.').pop()}`,
        file: file,
        notify: (arg) => this.$toast(arg)
      }).then((result) => {
        user.profilePic = result;
        if (!user.uoid) {
          user.uoid = this.$route.params.uoid;
        }
         return this.$store
          .dispatch("userManagement/updateUser", {
            orgId: this.$store.state.organisation.id,
            user: user,
          })     
      }).then(() => {
        return true;
        
      }).catch(error => {
        console.log(error.message)
         this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error!",
                text: error.message,
                icon: "AlertCirleIcon",
                variant: "danger",
              },
            });
            return true;
      })
    },
    
    fetchUsers() {
      if (
        !(
          this.$store.state.userManagement.users &&
          this.$store.state.userManagement.users.length > 1
        )
      ) {
        this.$store.dispatch(
          "userManagement/fetchUsers",
          this.$store.state.organisation.id
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>